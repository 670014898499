import * as React from 'react'
import Page from "../components/Page";
// import { Link } from "gatsby";
import './home.scss';
import Ferbasaurus from "../pages-support/home/Ferbasaurus";


const Index = (graphqlData, ...extraArgs) => {
  return <Page pageName="home">
    <Ferbasaurus/>
  </Page>;
};

export default Index

import * as React from "react";
import Granim from 'granim';
import './Ferbasaurus.scss';


export default class Ferbasaurus extends React.Component {

  componentDidMount() {
    // see https://github.com/sarcadass/granim.js
    new Granim({
      element: '#gradient-canvas',
      name: 'granim',
      opacity: [1, 1],
      states : {
        "default-state": {
          gradients: [
            ['#192a80', '#45735a'],
            ['#095266', '#5c9977'],
            ['#24b369', '#095266'],
            ['#45735a', '#192a80'],
          ]
        }
      },
      loop: true,
      direction: 'top-bottom'
    });
  }

  render() {
    return <div className="ferbasaurus container">
      <canvas id="gradient-canvas"></canvas>
      <h1>Ferbasaurus</h1>

      <div className="columns is-multiline">
        <Definition word="ferbasaurus">
          <li>a reference source in print or electronic form containing ferbonyms</li>
          <li>An overweight Ferber</li>
        </Definition>

        <Definition word="ferbify">
          <li>to do as a Ferber would do</li>
        </Definition>

        <Definition word="ferbivore">
          <li>Those who eat what Ferbers eat</li>
        </Definition>

        <Definition word="ferbography">
          <li>study of location of all Ferbers in the world</li>
        </Definition>


        <Definition word="ferbonomy">
          <li>the search for extra-terrestrial Ferbers</li>
        </Definition>

        <Definition word="ferbtastic">
          <li>this needs no definition. It is ferbtastic.</li>
        </Definition>

        <Definition word="ferbble">
          <li>to overwhelm a non-Ferber with strong Ferber personality</li>
        </Definition>

        <Definition word="ferbose">
          <li>Using more words than what a Ferber will sit through</li>
        </Definition>

        <Definition word="ferbonym">
          <li>a word having the same or nearly the same meaning as another in Ferbish. (eg, Ferbtastic and Ferbcellent)</li>
        </Definition>
        <Definition word="ferberite">
          <li>a mineral FeWO4 consisting of a valuable ferrous tungstate occurring in black granular masses</li>
          <li>a Ferber under four feet tall</li>
          <li>Ferber kryptonite: Hungarian desserts (kremes, gesztenyepüré, etc)</li>
        </Definition>
      </div>
    </div>;
  }
}

function Definition({ word, children }) {
  return <div className="column is-full-mobile is-half-desktop">
    <div className="card">
      <dl>
        <dt>{ word }</dt>
        <dd>
          <ol>
            { children }
          </ol>
        </dd>
      </dl>
    </div>
  </div>
}

/*

<dl>
        <dt>ferbaby</dt>
        <dd>
          <ol>
            <li>Ferber under the age of two years old. (ferbonym of "ferbrat.")</li>
            <li>Ferber who is acting cranky (typically due to hunger.)</li>
          </ol>
        </dd>
      </dl>

      <dl>
        <dt>ferbcrastinate</dt>
        <dd>
          <ol>
            <li>putting off a task that isn't ferbtastic</li>
          </ol>
        </dd>
      </dl>
 */

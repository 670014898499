import React from "react"
import Helmet from 'react-helmet'
import 'typeface-montserrat'
import dotProp from 'dot-prop';
import { StaticQuery, graphql } from 'gatsby'
import '../scss-support/_bulma-custom.scss';
import './Page.scss';


const Page = ({ children, pageName }) => {
  return <>
    <PageHead pageName={pageName}/>
    <div className={ `page-content` }>
      {children}
    </div>
  </>;
};


function PageHead({ pageName, bodyClass }) {
  const bodyAttributes = {
    class: [ `${pageName}-page`, bodyClass || '' ].join(' ').trim()
  };
  // site.siteMetadata set in gatsby-config.js. Only title is used really, which could be hardcoded but leaving as a graphql query for now
  // note: gatsby graphql actually replaces the query expression (the graphql`someQuery` stuff) in the AST. And can't use expression interpolation either. (If they brought out the big cannons, I wonder why they used tagged template syntax?) Seems like a misguided use of the clever stick.
  // htmlAttributes ignored in Helmet todo: maybe investigate (for setting background on html el)
  return <StaticQuery
    query={graphql `
      query HeadingQuery {
        site {
          siteMetadata {
            title
            baseUrl
          }
        }
      }
    `}
    render={data => {
      const meta = dotProp.get(data, 'site.siteMetadata');
      return <Helmet bodyAttributes={bodyAttributes}>
        <html lang="en" />
        <title>{meta.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="robots" content="noarchive" />

        {/*<meta name="description" content={siteMetadata.siteDescription} />*/}
        <meta property="og:locale" content="en_US" />
        {/*<meta property="og:site_name" content="ferbs.com" />*/}
        <meta property="og:url" content={meta.baseUrl} />
        <meta property="og:title" content={meta.title} />

        {/*<meta property="og:description" content={siteMetadata.siteDescription} />*/}

        { /* favicons created using https://realfavicongenerator.net */}
        <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-touch-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-touch-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/favicons/apple-touch-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/favicons/apple-touch-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png"/>
        <link rel="manifest" href="/favicons/site.webmanifest"/>
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#5bbad5"/>
        <link rel="shortcut icon" href="/favicons/favicon.ico"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="msapplication-config" content="/favicons/browserconfig.xml"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>;
    }}
  />
}

export default Page;

